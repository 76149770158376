const title = "Quiropráctica En";
const title_bajada = "Bebés Y Niños";
const description =
  "Cada vez más padres eligen el cuidado quiropráctico para sus hijos. Muchos problemas de la columna vertebral observados en adultos empezaron a una edad tan temprana como lo es el momento del parto.";
const url_img = `banner_ninos.jpg`;

const title_section_1 = "Quiropráctica En Bebés Y Niños";
const description_left_section_1 =
  "Cada vez más padres eligen el cuidado quiropráctico para sus hijos. Muchos problemas de la columna vertebral observados en adultos empezaron a una edad muy temprana, como lo es el momento del parto. Incluso los llamados métodos de parto “natural” pueden provocar estrés en la columna del bebé y en el sistema nervioso. La irritación del sistema nervioso resultante, causada por una mala alineación espinal y craneal, pueden ser la causa de muchos de los problemas en bebés recién nacidos.";
const description_right_section_1 =
  "El 65% del desarrollo del sistema nervioso (cerebro y nervios) se hace durante el primer año de vida. En esta etapa es vital que el sistema nervioso esté saludable para que el niño pueda crecer y desarrollar su potencial al máximo.";

const title_section_2 = "¿Cómo se benefician los niños de la Quiropráctica?";
const description_section_2 = "La Quiropráctica ayuda a garantizar que las complejas vías de comunicación entre el cerebro y el cuerpo del niño estén libres de interferencias. Es útil tanto durante el embarazo, como en el parto y posteriormente en la infancia, ayudando al niño a alcanzar salud y bienestar. "

const title_section_3 = "Razones por las cuales numerosos padres eligen el cuidado quiropráctico para sus hijos";

const description_left_section_3 = ['Fortalecer la inmunidad y reducir los riesgos de resfriado, otitis, asma, alergias, etc.', 'Ayudar a los problemas de digestion como cólicos y reflujos.', 'Ayudar al bebé y niño a dormir mejor.', 'Prevenir y ayudar problemas de espalda.'];

const description_right_section_3 = ['Para que crezcan en mejor salud naturalmente sin necesidad de medicamentos.', 'Para un desarrollo optimo del sistema neurológico.', 'Para mejorar la capacidad de concentración y aprendizaje.', 'Para estabilidad emocional y ayudar con hiperactividad o nerviosismo.'];

const itemData = [
    {
      img: 'bebe1.jpg',
      title: 'Bebe 1',
      rows: 2,
      cols: 2,
    },
    {
      img: 'bebe2.jpg',
      title: 'Bebe 2',
    },
    {
      img: 'bebe3.jpg',
      title: 'Bebe 3',
    },
    {
      img: 'bebe4.jpg',
      title: 'Bebe 4',
      cols: 2,
    }
  ];

export {
  title,
  title_bajada,
  description,
  url_img,
  title_section_1,
  description_left_section_1,
  description_right_section_1,
  title_section_2,
  description_section_2,
  title_section_3,
  description_left_section_3,
  description_right_section_3,
  itemData
};
