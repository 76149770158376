const title = "¿Qué esperar en";
const title_bajada = "tu primera visita?";
const description =
  "Empezaremos por analizar tu historial para ver si podemos ayudarte.";
const url_img = `banner_quiropractica.jpg`;

const title_section_1 = "1. HISTORIA Y CUESTIONARIO";
const description_section_1 =
  "Empezaremos por hacer un seguimiento de su historia. Esto tiene por objetivo descubrir cómo es su estilo de vida, si tiene estrés laboral y/o emocional, los traumas físicos que ha sufrido, los tratamientos o operaciones que haya recibido y cuáles son las metas que quiere conseguir con la quiropráctica.";
const url_img_section_1 = "visita1.png";

const title_section_2 = "2. PRUEBAS Y AJUSTE QUIROPRÁCTICO";
const description_section_2 =
  "En la segunda parte de la visita, haremos una serie de pruebas que incluyen un análisis de su postura, rango de movimiento y estado de su columna vertebral y sistema nervioso. A través de esto podremos saber cuál es su nivel de salud, y si la Quiropráctica le puede ayudar. Después el examen, procederemos con el ajuste quiropráctico. Dependiendo de lo que se encuentre durante las pruebas, es posible que se necesiten radiografías antes de que se entregue el ajuste quiropráctico.";
const url_img_section_2 = "visita2.png";

const title_section_3 = "3. INFORME Y RECOMENDACIONES";
const description_section_3 =
  "Con la información recopilada en su primera visita le prepararemos un informe quiropráctico por escrito, el cual se lo explicaremos en su segunda visita. El objetivo de este informe, es profundizar su conocimiento sobre la quiropráctica, antes de seguir con un programa de cuidado. Durante esta visita, y teniendo en cuenta los resultados expuestos en su informe, le recomendaremos un plan de  cuidado quiropráctico e integral, para lograr los cambios que usted espera conseguir y le explicaremos las pautas a seguir durante este proceso.";
const url_img_section_3 = "visita3.png";

export {
  title,
  title_bajada,
  description,
  url_img,
  title_section_1,
  description_section_1,
  url_img_section_1,
  title_section_2,
  description_section_2,
  url_img_section_2,
  title_section_3,
  description_section_3,
  url_img_section_3,
};
