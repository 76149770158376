import React, { useContext, useEffect } from "react";
import { Banner } from "../../components/shared";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import { title, title_bajada, description, url_img } from "./const";

const ErrorView = () => {
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);
  useEffect(() => {
    setMenuActive(0);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);

  return (
    <Banner
      title={title}
      title_bajada={title_bajada}
      description={description}
      url_img={url_img}
    />
  );
};

export default ErrorView;
