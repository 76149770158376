const title = "Durante";
const title_bajada = `el embarazo`;
const description =
  "El embarazo es un periodo durante el cual el cuerpo de la madre debe pasar por, literalmente, millones de cambios.";
const url_img = `banner_embarazo.jpg`;

const title_section_1 = `Durante el embarazo`;
const description_section_1 =
  "El embarazo es un periodo durante el cual el cuerpo de la madre debe pasar por, literalmente, millones de cambios. Los ajustes quiroprácticos te asistirán en este proceso permitiéndole a tu cuerpo funcionar de manera óptima, lo que te beneficia a ti y a tu bebé.";
const url_img_section_1 = `embarazo.png`;

const title_section_3 = `Entre los resultados esperados se incluyen:`;
const description_left_section_3 = ['Mejoría general de salud.','Colocación óptima del bebé en la pelvis.','Reducción de la duración del parto.', 'Las investigaciones científicas muestran que el cuidado quiropráctico durante el embarazo reduce la duración del parto para primerizas un 22% y un 33% para las madres que han dado a luz previamente.', 'Reducción del dolor de espalda y pélvico - Permite que un 85% de las embarazadas tengan una gravidez libre de dolores.', 'Aumento de la facilidad de adaptación durante los cambios naturales relacionados con el embarazo.'];
const description_right_section_3 = ['Aumento del espacio pélvico, facilitando así el crecimiento del bebé y mejorando la facilidad y comodidad durante el parto.', 'Mejoría de la digestión.', 'Una conciencia corporal más profunda.', 'Aumento en la apreciación de todos los procesos que ocurren durante la gestación, lo que te permite disfrutar de tu embarazo.', 'El cuidado quiropráctico para la mujer durante el embarazo ofrece unos cimientos sólidos para el bienestar. En el transcurso del embarazo, el estilo de vida quiropráctico te ofrece elecciones y beneficios para que disfrutes de una mayor salud y bienestar.'];

export {
  title,
  title_bajada,
  description,
  url_img,
  title_section_1,
  description_section_1,
  url_img_section_1,
  title_section_3,
  description_left_section_3,
  description_right_section_3,
};
