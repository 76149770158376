const title = `Contacto`;
const title_bajada = `Solicita Cita y Mejora tu Salud`;
const description =
  "¿Esta listo a hacerse cargo de su propia salud, confiar en su cuerpo y liberar su increíble potencial de curación interna?";
const url_img = `banner_somos.jpg`;

const title_section_2 = `Horarios de Consulta Abril:`;
const description_left_section_2 = [
  "Martes: 10:00 a 12:00 y 17:00 a 20:00.",
  "Miércoles: 10:00 a 12:00 y 17:00 a 20:00.",
];
const description_right_section_2 = [
  "Jueves: 17:00 a 20:00.",
  "Viernes: 10:00 a 12:00 y 17:00 a 20:00.",
];

export {
  title,
  title_bajada,
  description,
  url_img,
  title_section_2,
  description_left_section_2,
  description_right_section_2,
};
