import { React, useContext, useEffect } from "react";
import {
  Banner,
  BtnOferta,
  SectionList,
  SectionText,
  SectionTextCenter,
} from "../../components/shared";
import {
  title,
  title_bajada,
  description,
  url_img,
  title_section_1,
  description_left_section_1,
  description_right_section_1,
  title_section_2,
  description_section_2,
  title_section_3,
  description_left_section_3,
  description_right_section_3,
  itemData,
} from "./constBebes";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Container } from "@mui/material";
import goToTop from "../../hooks/useTop";
import { UserContext } from "../../context/UserContext";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const BebesNinosView = () => {
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);
  useEffect(() => {
    setMenuActive(3);
    setSubmenuActive(4);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Banner
        title={title}
        title_bajada={title_bajada}
        description={description}
        url_img={url_img}
      />

      <SectionText
        title={title_section_1}
        description_left={description_left_section_1}
        description_right={description_right_section_1}
      />

      <SectionTextCenter
        title={title_section_2}
        background={"grey-color"}
        description={description_section_2}
        center={false}
      />

      <SectionList
        title={title_section_3}
        description_left={description_left_section_3}
        description_right={description_right_section_3}
      />

      <div className="section">
        <Container>
          <ImageList cols={4}>
            {itemData.map((item) => (
              <ImageListItem
                key={item.img}
                cols={item.cols || 1}
                rows={item.rows || 1}
              >
                <img
                  {...srcset(
                    process.env.REACT_APP_FRONTEND + "assets/image/" + item.img,
                    121,
                    item.rows,
                    item.cols
                  )}
                  alt={item.title}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Container>
      </div>
      <BtnOferta />
    </>
  );
};

export default BebesNinosView;
