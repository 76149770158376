import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { React, useContext, useEffect } from "react";
import { Banner, BtnOferta, SectionList } from "../../components/shared";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import {
  title,
  title_bajada,
  description,
  url_img,
  title_section_2,
  description_left_section_2,
  description_right_section_2,
} from "./const";
import FormContact from "./FormContact";

const ContactoView = () => {
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(5);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Banner
        title={title}
        title_bajada={title_bajada}
        description={description}
        url_img={url_img}
      />
      <div className="section">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div
                className="section__container__img"
                style={{
                  backgroundImage:
                    "url(" +
                    process.env.REACT_APP_FRONTEND +
                    "assets/image/contacto.png)",
                }}
              ></div>
            </Grid>
            <Grid
              className="section__container__text right"
              item
              xs={12}
              md={6}
            >
              <h4>Contacto</h4>
              <p>
                  <br />
                ➤ Plaza Àngel Guimerà, 4, 08340 Vilassar De Mar, Barcelona.
                <br />
                <br />
                ✆ 937 525 355 | 695 493 290.
                <br />
                <br />✉ hola@maresmequiropractic.com
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* <SectionList
        title={title_section_2}
        background={"grey-color"}
        description_left={description_left_section_2}
        description_right={description_right_section_2}
      /> */}
      <div className="section">
        <Container>
          <Grid container spacing={2}>
            <Grid className="section__container__text" item xs={12}>
              <h4>{title_section_2}</h4>
            </Grid>
            <Grid className="section__container__text" item xs={12}>
              <div
                  className="section__container__img"
                  style={{
                    backgroundImage:
                      "url(" +
                      process.env.REACT_APP_FRONTEND +
                      "assets/image/calendar.png)",
                  }}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
      <FormContact />
      <BtnOferta />
    </>
  );
};

export default ContactoView;
