import { React, useContext, useEffect } from "react";
import { Banner, SectionVideo, SectionText, BtnOferta } from "../../components/shared";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import {
  title,
  title_bajada,
  description,
  url_img,
  title_section_1,
  description_left_section_1,
  description_right_section_1,
  title_section_2,
  description_section_2,
  title_video_section_2,
  url_video_section_2,
} from "./constQuiropractica";

const QuiropracticaView = () => {
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(2);
    setSubmenuActive(1);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Banner
        title={title}
        title_bajada={title_bajada}
        description={description}
        url_img={url_img}
      />
      <SectionText
        title={title_section_1}
        description_left={description_left_section_1}
        description_right={description_right_section_1}
      />

      <SectionVideo
        title={title_section_2}
        description={description_section_2}
        left={false}
        url_video={url_video_section_2}
        title_video={title_video_section_2}
        background={"grey-color"}
      />
      <BtnOferta />
    </>
  );
};

export default QuiropracticaView;
