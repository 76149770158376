import { postWithoutToken } from "../helpers/post";

const sendForm = async (
  values,
  setLoader,
  setValidated,
  setErrorNombre,
  setErrorEmail,
  setErrorPhone,
  setMjeErrorEmail,
  setValues,
  initialValues,
  setSuccess
) => {
  let validationName = false;
  let validationPhone = false;
  let validationEmail = false;
  let validationEmailCorrect = false;
  setLoader(true);
  setValidated("");
  if (values.name_person === "") {
    setErrorNombre(true);
    validationName = false;
  } else {
    setErrorNombre(false);
    validationName = true;
  }

  if (values.mail === "") {
    setErrorEmail(true);
    setMjeErrorEmail("Ingrese un email.");
    validationEmail = false;
  } else {
    validationEmail = true;
    var expresion = /^[a-z][\w.-]+@\w[\w.-]+\.[\w.-]*[a-z][a-z]$/i;
    if (!expresion.test(values.mail)) {
      setErrorEmail(true);
      setMjeErrorEmail("Ingrese un email correcto.");
      validationEmailCorrect = false;
    } else {
      setErrorEmail(false);
      setMjeErrorEmail("");
      validationEmailCorrect = true;
    }
  }

  if (values.phone === "") {
    setErrorPhone(true);
    validationPhone = false;
  } else {
    setErrorPhone(false);
    validationPhone = true;
  }

  if (
    validationName &&
    validationEmail &&
    validationPhone &&
    validationEmailCorrect
  ) {
    const response = await postWithoutToken("sendForm", values);
    if (response.data.status === 201) {
      setValidated("Consulta enviada con éxito");
      setErrorNombre(false);
      setErrorEmail(false);
      setMjeErrorEmail("");
      setValues(initialValues);
      setSuccess(true);
      setLoader(false);
      return true;
    }
    // errores
    const { message } = response.data;
    let error = "";
    if (message instanceof Object) {
      error = Object.values(message)
        .map((item) => item.join(", "))
        .join(", ");
    } else {
      error = message;
    }
    setValidated(error);
    setLoader(false);
    return false;
  } else {
    setLoader(false);
    return false;
  }
};

export default sendForm;
