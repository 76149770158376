import { React, useContext, useEffect } from "react";
import {
  Banner,
  BtnOferta,
  SectionList,
  SectionTextCenter,
} from "../../components/shared";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import {
  title,
  title_bajada,
  description,
  url_img,
  title_section_1,
  description_left_section_1,
  description_right_section_1,
  description_section_2,
} from "./constDolores";

const DoloresView = () => {
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(3);
    setSubmenuActive(8);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Banner
        title={title}
        title_bajada={title_bajada}
        description={description}
        url_img={url_img}
      />

      <SectionList
        title={title_section_1}
        description_left={description_left_section_1}
        description_right={description_right_section_1}
      />

      <SectionTextCenter
        background={"grey-color"}
        description={description_section_2}
        center={true}
      />
      <BtnOferta />
    </>
  );
};

export default DoloresView;
