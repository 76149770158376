import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { Collapse, Container } from "@mui/material";
import { UserContext } from "../../context/UserContext";

const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const Navbar = () => {
  const { menuActive, submenuActive } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [colorChange, setColorchange] = useState(false);
  const [openClientes, setOpenClientes] = useState(false);
  const [openQuiropractica, setOpenQuiropractica] = useState(false);

  const handleClick = () => {
    setOpenQuiropractica(false);
    setOpenClientes(!openClientes);
  };

  const handleClickQuiropractica = () => {
    setOpenClientes(false);
    setOpenQuiropractica(!openQuiropractica);
  };

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  const handleDrawerOpen = () => {
    setOpenClientes(false);
    setOpenQuiropractica(false);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const year = new Date().getFullYear();
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        className={colorChange ? "navbar__main no-transparent" : "navbar__main"}
        position="fixed"
        open={open}
      >
        <Container fixed>
          <Toolbar className="navbar__content">
            <Typography
              variant="h6"
              noWrap
              sx={{ flexGrow: 1 }}
              component="div"
            >
              <Link to="/">
                <img
                  src={`${
                    process.env.REACT_APP_FRONTEND +
                    "assets/image/logo-secondary.png"
                  }`}
                  alt="Logo Druky"
                  className="navbar__logo"
                />
              </Link>
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={{ ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
        className="navbar__menu"
      >
        <DrawerHeader className="navbar__menu__header">
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <List>
          <ListItem key="Inicio" disablePadding>
            <ListItemButton>
              <Link to="/" onClick={handleDrawerClose}>
                <ListItemText
                  primary="Inicio"
                  className={menuActive === 1 ? "navbar__menu__active" : ""}
                />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItemButton
            className="navbar__menu__option__collapse"
            onClick={handleClickQuiropractica}
          >
            <ListItemText
              primary="La Quiropráctica"
              className={menuActive === 2 ? "navbar__menu__active" : ""}
            />
            {openQuiropractica ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            className="navbar__menu__collapse"
            in={openQuiropractica}
            timeout="auto"
            unmountOnExit
          >
            <ListItem key="¿Qué es la Quiropráctica?" disablePadding>
              <ListItemButton>
                <Link to="/quiropractica" onClick={handleDrawerClose}>
                  <ListItemText
                    primary="¿Qué es la Quiropráctica?"
                    className={
                      submenuActive === 1 ? "navbar__submenu__active" : ""
                    }
                  />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem key="Primera Visita" disablePadding>
              <ListItemButton>
                <Link to="/primera-visita" onClick={handleDrawerClose}>
                  <ListItemText
                    primary="Primera Visita"
                    className={
                      submenuActive === 2 ? "navbar__submenu__active" : ""
                    }
                  />
                </Link>
              </ListItemButton>
            </ListItem>
          </Collapse>
          <ListItemButton
            className="navbar__menu__option__collapse"
            onClick={handleClick}
          >
            <ListItemText
              primary="Nuestros Clientes"
              className={menuActive === 3 ? "navbar__menu__active" : ""}
            />
            {openClientes ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            className="navbar__menu__collapse"
            in={openClientes}
            timeout="auto"
            unmountOnExit
          >
            <ListItem key="salud-y-bienestar" disablePadding>
              <ListItemButton>
                <Link to="/salud-y-bienestar" onClick={handleDrawerClose}>
                  <ListItemText
                    primary="Salud y Bienestar"
                    className={
                      submenuActive === 3 ? "navbar__submenu__active" : ""
                    }
                  />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem key="bebes-y-niños" disablePadding>
              <ListItemButton>
                <Link to="/bebes-y-ninos" onClick={handleDrawerClose}>
                  <ListItemText
                    primary="Bebés y Niños"
                    className={
                      submenuActive === 4 ? "navbar__submenu__active" : ""
                    }
                  />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem key="durante-el-embarazo" disablePadding>
              <ListItemButton>
                <Link to="/durante-el-embarazo" onClick={handleDrawerClose}>
                  <ListItemText
                    primary="Durante el Embarazo"
                    className={
                      submenuActive === 5 ? "navbar__submenu__active" : ""
                    }
                  />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem key="tercera-edad" disablePadding>
              <ListItemButton>
                <Link to="/tercera-edad" onClick={handleDrawerClose}>
                  <ListItemText
                    primary="Tercera Edad"
                    className={
                      submenuActive === 6 ? "navbar__submenu__active" : ""
                    }
                  />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem key="deporte" disablePadding>
              <ListItemButton>
                <Link to="/deporte" onClick={handleDrawerClose}>
                  <ListItemText
                    primary="Deporte"
                    className={
                      submenuActive === 7 ? "navbar__submenu__active" : ""
                    }
                  />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem key="dolor-espalda" disablePadding>
              <ListItemButton>
                <Link to="/dolor-espalda" onClick={handleDrawerClose}>
                  <ListItemText
                    primary="Dolor de Espalda"
                    className={
                      submenuActive === 8 ? "navbar__submenu__active" : ""
                    }
                  />
                </Link>
              </ListItemButton>
            </ListItem>
          </Collapse>
          <ListItem key="Quienes Somos" disablePadding>
            <ListItemButton>
              <Link to="/equipo" onClick={handleDrawerClose}>
                <ListItemText
                  primary="Quienes Somos"
                  className={menuActive === 4 ? "navbar__menu__active" : ""}
                />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItem key="Contacto" disablePadding>
            <ListItemButton>
              <Link to="/contacto" onClick={handleDrawerClose}>
                <ListItemText
                  primary="Contacto"
                  className={menuActive === 5 ? "navbar__menu__active" : ""}
                />
              </Link>
            </ListItemButton>
          </ListItem>
        </List>
        <div className="navbar__menu__footer">
          <div>
            <IconButton href="https://www.facebook.com/maresmequiropractic/">
              <FacebookIcon />
            </IconButton>
            <IconButton href="https://www.instagram.com/maresmequiropractic/">
              <InstagramIcon />
            </IconButton>
          </div>
          <div>
            <p>© {year} Maresme Quiropráctic</p>
          </div>
        </div>
      </Drawer>
    </Box>
  );
};

export default Navbar;
