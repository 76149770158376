import { Container } from "@mui/system";
import React from "react";
import { Grid } from "@mui/material";

const SectionText = ({
  title,
  description_left,
  description_right,
  background = "",
}) => {
  return (
    <div className={"section " + background}>
      <Container>
        <Grid container spacing={2}>
          <>
            <Grid className="section__container__text" item xs={12}>
              <h4>{title}</h4>
            </Grid>
            <Grid className="section__container__text start" item xs={12} md={6}>
              <p>{description_left}</p>
            </Grid>
            <Grid className="section__container__text start right" item xs={12} md={6}>
              <p>{description_right}</p>
            </Grid>
          </>
        </Grid>
      </Container>
    </div>
  );
};

export default SectionText;
