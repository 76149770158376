import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { IconButton } from "@mui/material";

const BtnWhatsApp = () => {
  return (
    <div className="container__btn__whatsapp">
      <IconButton href="https://wa.me/34695493290">
        <WhatsAppIcon />
      </IconButton>
    </div>
  );
};

export default BtnWhatsApp;
