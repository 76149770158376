const images = [
  "carrousel_1.jpg",
  "carrousel_2.jpg",
  "carrousel_3.jpg",
  "carrousel_4.jpg",
  "carrousel_5.jpg",
  "carrousel_6.jpg",
  "carrousel_7.jpg",
  "carrousel_8.jpg",
  "carrousel_9.jpg",
];

const title = `Bienvenidos a`;
const title_bajada = `Maresme Quiropráctica`;
const description =
  "Centro Quiropráctico en Vilassar de Mar. Mejora tu salud y tu calidad de vida de forma sencilla y natural.";
const url_img = `banner_home.jpg`;

const title_bienvenido = "Bienvenidos a Maresme Quiropráctica";
const description_bienvenido =
  "Somos un equipo de quiroprácticos radicado en la comunidad de Vilassar de Mar y la Costa del Maresme, con muchos años de experiencia. Nuestra misión es inspirar a la Comunidad a hacerse cargo de su propia salud de forma sencilla y natural. En tu primera cita, nuestros quiroprácticos evaluarán minuciosamente tu estado de salud, revisarán tu historial, te realizarán un análisis de postura, un examen de la columna y varias pruebas para chequear tu buen funcionamiento neurológico.";
const url_bienvenido = "bienvenido.jpg";

const title_section_1 = "Tu salud y la de tu Familia nos importa";
const description_section_1 =
  "En Maresme Quiropráctica queremos Inspirar A Nuestra Comunidad A Hacerse Cargo De Su Propia Salud de Forma Sencilla Y Natural.";
const url_img_section_1 = "familia.png";

const title_que_es_1 = "¿Qué es la quiropráctica?";
const description_que_es_1 =
  "Tu cuerpo tiene la capacidad innata de adaptarse, autocurarse y autorregularse; lo hace a través del sistema nervioso central. Sin embargo, cuando está bajo los efectos constantes del estrés diario, esta capacidad de sanación puede verse disminuida. La quiropráctica busca restaurar la función física y neurológica para que su cuerpo pueda sanar como se supone que debe hacerlo. Ya sea que esté luchando con síntomas específicos o que simplemente quiera ser más proactivo,la quiropráctica podría ser la solución que está buscando.";
const url_que_es = "adecuada.jpg";

const title_adecuada = "¿La quiropráctica es adecuada para ti?";
const description_adecuada =
  "Todos son bienvenidos en nuestra consulta. Ofrecemos una amplia gama de técnicas suaves para todas las edades, desde bebés y niños hasta adultos. Nos apasiona especialmente guiar a familias e individuos hacia un estilo de vida más proactivo, mejorando su calidad de vida y su salud. También ayudamos a deportistas que buscan mejorar su rendimiento, de la manera más simple y natural posible. Nuestros planes de atención personalizada buscan permitir que su cuerpo se recupere y funcione de la mejor manera.";
const url_adecuada = "que_es.jpg";

const title_section_2 = "Introducción A La Quiropráctica";
const description_section_2 =
  "Un quiropráctico es un profesional sanitario que se especializa en la salud y la función de la columna vertebral y el sistema nervioso. Mucha gente piensa que los quiroprácticos solo pueden ayudar con problemas tales como dolor de espalda, cuello y de cabeza. Es verdad que ayudan a menudo a la gente con dolor, pero hay muchos más.";
const title_video_section_2 = "video_quiropractica";
const url_video_section_2 =
  "https://player.vimeo.com/video/340240468?wmode=opaque&amp;autoplay=1";

export {
  images,
  title,
  title_bajada,
  description,
  url_img,
  title_bienvenido,
  title_section_1,
  description_bienvenido,
  url_bienvenido,
  description_section_1,
  title_que_es_1,
  description_que_es_1,
  url_que_es,
  title_section_2,
  url_img_section_1,
  description_section_2,
  title_video_section_2,
  url_video_section_2,
  title_adecuada,
  description_adecuada,
  url_adecuada,
};
