const title = "Dolor de espalda";
const title_bajada = "y otros problemas";
const description =
  "Algunas de las razones más comunes de consulta en nuestro centro.";
const url_img = `banner_home.jpg`;

const title_section_1 = `Algunas de las razones más comunes de consulta en nuestro centro son:`;
const description_left_section_1 = ['Problemas de digestión, estrés, ansiedad, falta de energía, insomnio, mala postura, desequilibrio hormonal, alta presión arterial.', 'Dolor de espalda o dolor articular.', 'Dolor de piernas (nervio ciático).'];
const description_right_section_1 = ['Problemas de disco intervertebral (hernia de disco, degeneración, etc).', 'Migrañas o otro tipo de dolores de cabeza.'];

const description_section_2 = 'Si padeces síntomas o problemas similares, no dudes en contactarnos o visitar nuestro centro para que podamos atender tu problema, y evaluar si podemos ayudarte o simplemente re dirigirte al especialista médico adecuado.';

export {
  title,
  title_bajada,
  description,
  url_img,
  title_section_1,
  description_left_section_1,
  description_right_section_1,
  description_section_2,
};
