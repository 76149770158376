import { Container } from "@mui/system";
import React from "react";
import { Grid } from "@mui/material";

const SectionImg = ({ title, description, left, url_img, background = "" }) => {
  return (
    <div className={"section " + background}>
      <Container>
        <Grid container spacing={2}>
          {left && (
            <>
              {" "}
              <Grid item xs={12} md={6}>
                <div
                  className="section__container__img"
                  style={{
                    backgroundImage:
                      "url(" +
                      process.env.REACT_APP_FRONTEND +
                      "assets/image/" +
                      url_img +
                      ")",
                  }}
                ></div>
              </Grid>
              <Grid
                className="section__container__text right"
                item
                xs={12}
                md={6}
              >
                {title && <h4>{title}</h4>}
                <p>{description}</p>
              </Grid>
            </>
          )}
          {!left && (
            <>
              <Grid className="section__container__text" item xs={12} md={6}>
                {title && <h4>{title}</h4>}
                <p>{description}</p>
              </Grid>
              <Grid
                className="section__container__text right"
                item
                xs={12}
                md={6}
              >
                <div
                  className="section__container__img"
                  style={{
                    backgroundImage:
                      "url(" +
                      process.env.REACT_APP_FRONTEND +
                      "assets/image/" +
                      url_img +
                      ")",
                  }}
                ></div>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default SectionImg;
