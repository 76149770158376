const title = "Deportes Y Salud";
const title_bajada = "A La Vanguardia";
const description =
  "Muchos atletas aficionados y profesionales utilizan la Quiropráctica, ya sea para ayudar en la recuperación de una lesión o para la prevención de las mismas.";
const url_img = `banner_home.jpg`;

const title_section_1 = "Deportes Y Salud: A La Vanguardia";
const description_left_section_1 =
  "Muchos atletas aficionados y profesionales utilizan la Quiropráctica, ya sea para ayudar en la recuperación de una lesión o para la prevención de las mismas. En algunos casos es solo para optimizar su rendimiento. Muchos atletas se sientan en el banquillo porque su lesión no responde al tratamiento convencional. Otros continúan jugando, pero a un menor rendimiento del que son capaces, simplemente porque su sistema nervioso y estructural no se encuentran en equilibrio. La mayoría de los deportes supone contacto corporal, así como arranques y frenadas rápidas, lo cual pone una cantidad de tensión inusual en la espalda y sistema estructural. Los quiroprácticos consideran a la persona como un todo, dándole importancia especial tanto a la columna vertebral como al sistema nervioso, que afecta a todas las funciones del cuerpo.";
const description_right_section_1 =
  "La Quiropráctica es un método natural que pone énfasis en mantener a todos los sistemas del cuerpo funcionado de manera eficiente, con el fin de que el deportista logre su máximo rendimiento, sufra el mínimo riesgo de lesión y cuente con una capacidad de recuperación rápida. Muchos atletas de categoría mundial y olímpica, así como estrellas y equipos profesionales, han continuado su cuidado con quiroprácticos deportivos. Joe Montana, Nolan Ryan, Muhammad Ali, Kareem Abdul-Jubbar y Carl Lewis utilizan el cuidado quiropráctico. Los quiroprácticos han sido seleccionados como profesionales asistentes en los Juegos Olímpicos y en campeonatos nacionales y mundiales en pista y campo, ciclismo, voleibol, levantamiento de pesas, aerobic y triatlón.";

const title_section_2 = "Entre los resultados esperados se incluyen:";
const description_left_section_2 = [
  "Mejora del rendimiento.",
  "Recuperación más rápida de lesiones existentes.",
];
const description_right_section_2 = ["Prevención de lesiones."];

const dataComment = [
  {
    name: "Arnold Schwarzenegger",
    comment:
      "“Físico culturistas y personas que hacen ejercicio han estado utilizando, durante mucho tiempo, quiroprácticos con el objetivo de estar sanos y en forma. Yo descubrí la mejor manera, no sólo de curar mis dolencias sino también de prevenirlas, yendo al quiropráctico”.",
  },
  {
    name: "Tiger Woods",
    comment:
      "“Estuve yendo a quiroprácticos desde que me acuerdo. Es muy importante para mi entrenamiento así como practicar mi swing”.",
  },
  {
    name: "Evander Holyfield",
    comment:
      "“Necesito realizarme un ajuste antes de subir al ring. Realmente creo en la quiropráctica. Acudiendo al quiropráctico tres veces por semana mi rendimiento mejora. La mayoría de los boxeadores van para mantenerse en lo más alto”.",
  },
  {
    name: "Lance Armstrong",
    comment:
      "“No hubiera ganado sin la ayuda de mi quropráctico”.",
  },
  {
    name: "Jean Pierre Meersseman",
    comment:
      "(Chiropractor Coordinator AC Milan). “Este año descendió un 43% el número de días perdidos en entrenamiento por lesiones para aquellas personas que acuden regularmente al quiropráctico. Así como se redujo un 70% la administración de medicinas”.",
  },
];
export {
  title,
  title_bajada,
  description,
  url_img,
  title_section_1,
  description_left_section_1,
  description_right_section_1,
  title_section_2,
  description_left_section_2,
  description_right_section_2,
  dataComment,
};
