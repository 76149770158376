import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import { React, useState } from "react";
import sendForm from "../../services/form/sendForm";
import "./styles.scss";

const initialValues = {
  name_person: "",
  mail: "",
  phone: "",
};

const FormContact = () => {
  const [validated, setValidated] = useState("");
  const [values, setValues] = useState(initialValues);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorNombre, setErrorNombre] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [mjeErrorEmail, setMjeErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitForm = (event) => {
    event.preventDefault();
    sendForm(
      values,
      setLoader,
      setValidated,
      setErrorNombre,
      setErrorEmail,
      setErrorPhone,
      setMjeErrorEmail,
      setValues,
      initialValues,
      setSuccess
    );
  };

  return (
    <div className={"section"} id="section__form">
      <Container>
        <Grid className="section__container__text" item xs={12}>
          <h4>Solicita Cita y Mejora tu Salud</h4>
        </Grid>

        <Grid container spacing={2}>
          <>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  maxWidth: "100%",
                }}
              >
                {!errorNombre && (
                  <TextField
                    fullWidth
                    label="Nombre *"
                    name="name_person"
                    onChange={handleChange}
                    value={values.name_person}
                  />
                )}
                {errorNombre && (
                  <TextField
                    fullWidth
                    label="Nombre *"
                    name="name_person"
                    onChange={handleChange}
                    error
                    helperText="Ingrese un nombre."
                    value={values.name_person}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  maxWidth: "100%",
                }}
              >
                {!errorEmail && (
                  <TextField
                    fullWidth
                    label="Correo Electrónico *"
                    name="mail"
                    onChange={handleChange}
                    value={values.mail}
                  />
                )}
                {errorEmail && (
                  <TextField
                    fullWidth
                    label="Correo Electrónico *"
                    name="mail"
                    onChange={handleChange}
                    error
                    helperText={mjeErrorEmail}
                    value={values.mail}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  maxWidth: "100%",
                }}
              >
                {!errorPhone && (
                  <TextField
                    fullWidth
                    label="Teléfono *"
                    name="phone"
                    onChange={handleChange}
                    value={values.phone}
                  />
                )}
                {errorPhone && (
                  <TextField
                    fullWidth
                    label="Teléfono *"
                    name="phone"
                    onChange={handleChange}
                    error
                    helperText="Ingrese un teléfono."
                    value={values.phone}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              {validated !== "" && (
                <div className={success ? "text__succes" : "text__danger"}>
                  {validated}
                </div>
              )}
            </Grid>

            <Grid className="container__btn__contact" item xs={12} md={12}>
              <Button variant="outlined" onClick={submitForm}>
                {!loader && "Enviar"}
                {loader && <CircularProgress />}
              </Button>
            </Grid>
          </>
        </Grid>
      </Container>
    </div>
  );
};

export default FormContact;
