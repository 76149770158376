import { React, useContext, useEffect } from "react";
import { Banner, BtnOferta, SectionImg, SectionList } from "../../components/shared";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import {
  title,
  title_bajada,
  description,
  url_img,
  title_section_1,
  description_section_1,
  url_img_section_1,
  title_section_3,
  description_left_section_3,
  description_right_section_3,
} from "./constEmbarazo";

const EmbarazoView = () => {
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(3);
    setSubmenuActive(5);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Banner
        title={title}
        title_bajada={title_bajada}
        description={description}
        url_img={url_img}
      />
      <SectionImg
        title={title_section_1}
        description={description_section_1}
        left={true}
        url_img={url_img_section_1}
      />

      <SectionList
        title={title_section_3}
        background={"grey-color"}
        description_left={description_left_section_3}
        description_right={description_right_section_3}
      />
      <BtnOferta />
    </>
  );
};

export default EmbarazoView;
