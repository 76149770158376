import { Container } from "@mui/system";
import React from "react";
import { Grid } from "@mui/material";

const SectionText = ({
  description,
  title = null,
  center,
  background = "",
  objeto = false,
}) => {
  const result = center ? " text__center" : "";

  return (
    <div className={"section " + background}>
      <Container>
        <Grid container spacing={2}>
          <>
            {title && (
              <Grid className="section__container__text" item xs={12}>
                <h4>{title}</h4>
              </Grid>
            )}{" "}
            <Grid
              className={"section__container__text" + result}
              item
              xs={12}
              md={12}
            >
              {objeto &&
                description.map((item, index) => (
                  <p className="margin__bottom" key={index}>
                    {item}
                  </p>
                ))}
              {!objeto && <p>{description}</p>}
            </Grid>
          </>
        </Grid>
      </Container>
    </div>
  );
};

export default SectionText;
