const title = "Quiénes";
const title_bajada = "somos";
const description =
  "Somos un Centro Quiropráctico en Vilassar de Mar.";
const url_img = `banner_somos.jpg`;

const url_img_section_1 = "siri.jpg";

const url_img_section_2 = "michael.jpg";


export {
  title,
  title_bajada,
  description,
  url_img,
  url_img_section_1,
  url_img_section_2,
};
