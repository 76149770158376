const title = "La";
const title_bajada = "Quiropráctica";
const description =
  "La Quiropráctica es la ciencia, arte y filosofía que permite a las personas alcanzar su máximo potencial de forma natural.";
const url_img = `banner_quiropractica.jpg`;

const title_section_1 = "El cuidado del sistema nervioso";
const description_left_section_1 =
  "La Quiropráctica es la ciencia, arte y filosofía que permite a las personas alcanzar su máximo potencial de forma natural. El quiropráctico trabaja con la estructura más compleja del universo: el sistema nervioso central, el cual controla y coordina el cuerpo y su rendimiento. El quiropráctico debe asegurarse de que exista la mínima interferencia posible en el sistema nervioso, permitiendo así una adecuada comunicación entre las distintas partes del cuerpo. El sistema nervioso puede compararse a un ordenador capaz de procesar información rápidamente, para luego transmitir esa información de ida y vuelta entre el cerebro y el cuerpo.";
const description_right_section_1 =
  "Para que este proceso tenga lugar de forma correcta, es necesario tener una columna vertebral y sistema nervioso que funcionen a un nivel óptimo. La Quiropráctica no pretende tratar enfermedades o dolencias específicas, sino que busca reducir la interferencia causada por las subluxaciones, para mejorar la función del sistema nervioso, un factor esencial que ayuda al cuerpo a alcanzar su máximo potencial.";

const title_section_2 = "¿Quién puede ser paciente?";
const description_section_2 =
  "En el centro vemos a personas de todas las edades, desde bebés con días, hasta ancianos. Cualquiera puede beneficiarse de los cuidados quiroprácticos.";
const url_video_section_2 =
  "https://player.vimeo.com/video/340240468?wmode=opaque";
const title_video_section_2 = "Beginners Guide";

export {
  title,
  title_bajada,
  description,
  url_img,
  title_section_1,
  description_left_section_1,
  description_right_section_1,
  title_section_2,
  description_section_2,
  title_video_section_2,
  url_video_section_2,
};
