import { React, useState } from "react";
import { UserContext } from "../../context/UserContext";
import AppRouter from "../../router/AppRouter";

const MainApp = () => {
  const [menuActive, setMenuActive] = useState(0);
  const [submenuActive, setSubmenuActive] = useState(0);
  return (
    <UserContext.Provider value={{ menuActive, setMenuActive, submenuActive, setSubmenuActive }}>
      <AppRouter />
    </UserContext.Provider>
  );
};

export default MainApp;
