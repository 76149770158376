import { React, useContext, useEffect } from "react";
import { Banner, BtnOferta, SectionImg } from "../../components/shared";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import {
  title,
  title_bajada,
  description,
  url_img,
  title_section_1,
  description_section_1,
  url_img_section_1,
  title_section_2,
  description_section_2,
  url_img_section_2,
  title_section_3,
  description_section_3,
  url_img_section_3,
} from "./constVisita";

const PrimeraVisitaView = () => {
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(2);
    setSubmenuActive(2);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Banner
        title={title}
        title_bajada={title_bajada}
        description={description}
        url_img={url_img}
      />
      <SectionImg
        title={title_section_1}
        description={description_section_1}
        left={true}
        url_img={url_img_section_1}
      />
      <SectionImg
        title={title_section_2}
        description={description_section_2}
        left={false}
        url_img={url_img_section_2}
        background={"grey-color"}
      />
      <SectionImg
        title={title_section_3}
        description={description_section_3}
        left={true}
        url_img={url_img_section_3}
      />
      <BtnOferta />
    </>
  );
};

export default PrimeraVisitaView;
