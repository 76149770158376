import { Container, Grid } from "@mui/material";
import { React, useContext, useEffect } from "react";
import { Banner, BtnOferta } from "../../components/shared";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import {
  title,
  title_bajada,
  description,
  url_img,
  url_img_section_1,
  url_img_section_2,
} from "./const";

const PrimeraVisitaView = () => {
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(4);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);

  return (
    <>
      <Banner
        title={title}
        title_bajada={title_bajada}
        description={description}
        url_img={url_img}
      />
      <div className={"section"}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div
                className="section__container__img"
                style={{
                  backgroundImage:
                    "url(" +
                    process.env.REACT_APP_FRONTEND +
                    "assets/image/" +
                    url_img_section_1 +
                    ")",
                }}
              ></div>
            </Grid>
            <Grid
              className="section__container__text right"
              item
              xs={12}
              md={6}
            >
              <p>
                Hola,
                <br />
                <br />
                Soy Siri !! Nací y crecí en la hermosa región del Lago Maggiore
                en el norte de Italia. Tuve la suerte de nacer en una familia
                donde cuidar de nuestra salud y confiar en la inteligencia de
                nuestro cuerpo eran nuestros valores principales. Me encanta la
                quiropráctica y tuve el gran privilegio de crecer bajo el
                cuidado quiropráctico. Realicé mis 5 años de licenciatura en
                quiropráctica en uno de los colegios más conocidos del mundo en
                Nueva Zelanda. En marzo de 2019 me mudé a Cataluña para empezar
                un nuevo capítulo de mi vida y compartir el amor por mi
                profesión con mi nueva comunidad.
                <br />
                <br />
                Mi mayor tesoro es mi familia, es por ello que entiendo el
                fuerte sentimiento de querer solo lo mejor para ellos. Mi
                objetivo es compartir mi visión de la salud, inspirar y guiar a
                las familias hacia un nuevo mundo donde la salud se puede lograr
                de manera simple y natural a través de la confianza en la
                increíble capacidad de curación del cuerpo y el conocimiento de
                cómo funciona su sistema.
                <br />
                <br />
                Hablando desde mi familia, creo que nuestra salud y bienestar es
                nuestro mayor tesoro en este mundo. Sin ella no podemos hacer lo
                que amamos.
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className={"section grey-color"}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div
                className="section__container__img"
                style={{
                  backgroundImage:
                    "url(" +
                    process.env.REACT_APP_FRONTEND +
                    "assets/image/" +
                    url_img_section_2 +
                    ")",
                }}
              ></div>
            </Grid>
            <Grid
              className="section__container__text right"
              item
              xs={12}
              md={6}
            >
              <p>
                Hola a todos
                <br />
                <br />
                Me llamo Michael. Soy originario de Adelaida (Australia del
                Sur). Creciendo, pasaba gran parte del tiempo en el agua
                nadando, compitiendo en surf y natación, o como voluntario de
                salvavidas. En mi escuela secundaria estuve muy involucrado en
                roles de liderazgo y actividades comunitarias.
                <br />
                <br />
                Descubrí rápidamente que me encantaba ayudar a la gente. Esto
                fue lo que me inspiró a ser quiropráctico.
                <br />
                <br />
                Empecé a recibir atención quiropráctica a los 6 años, no por un
                problema en particular, sino porque mi familia sabía que era
                bueno para nosotros mientras crecíamos.
                <br />
                <br />
                Salí de Australia para vivir y estudiar en Auckland en el
                mundialmente reconocido Colegio de Quiropráctica de Nueva
                Zelanda.
                <br />
                <br />
                Después de 5 años de estudio y 3 años trabajando en una consulta
                privada, me mudé a esta hermosa comunidad de Vilassar De Mar,
                donde espero poder ayudar familias y compartir mi amor por la
                quiropráctica.
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>
      <BtnOferta />
    </>
  );
};

export default PrimeraVisitaView;
