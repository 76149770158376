import { Container } from "@mui/system";
import React from "react";

const Banner = ({
  title,
  title_bajada,
  description,
  url_img,
  positionBottom = false,
  btn_form = false,
}) => {
  const style = positionBottom
    ? {
        backgroundImage:
          "url(" +
          process.env.REACT_APP_FRONTEND +
          "assets/image/" +
          url_img +
          ")",
        backgroundPosition: "bottom",
      }
    : {
        backgroundImage:
          "url(" +
          process.env.REACT_APP_FRONTEND +
          "assets/image/" +
          url_img +
          ")",
      };
  return (
    <>
      <div className="banner_container" style={style}>
        <Container fixed>
          <h1>
            {title}
            <br />
            {title_bajada}
          </h1>
          <h4>{description}</h4>
          {btn_form && (
            <>
              <br />
              <div className="content__btn__banner">
                <a className="content__btn__reserva" href="#section__form">
                  <p>RESERVAR CITA</p>
                </a>
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default Banner;
