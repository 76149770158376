import { React, useContext, useEffect } from "react";
import { Banner, BtnOferta, SectionTextCenter } from "../../components/shared";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import {
  title,
  title_bajada,
  description,
  url_img,
  description_section_1,
} from "./constBienestar";

const SaludBienestarView = () => {
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(3);
    setSubmenuActive(3);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Banner
        title={title}
        title_bajada={title_bajada}
        description={description}
        url_img={url_img}
      />
      <SectionTextCenter
        description={description_section_1}
        center={true}
        objeto={true}
      />
      <BtnOferta />
    </>
  );
};

export default SaludBienestarView;
