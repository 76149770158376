import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar, Footer, BtnWhatsApp } from "../components/shared";
import {
  BebesNinosView,
  DeporteView,
  DoloresView,
  EmbarazoView,
  SaludBienestarView,
  TerceraEdadView,
} from "../views/Clientes";
import ContactoView from "../views/Contacto/ContactoView";
import ErrorView from "../views/Error";
import HomeView from "../views/Home";
import OfertaView from "../views/Oferta/OfertaView";
// import OfertaView from "../views/Oferta";
import QuienesSomosView from "../views/QuienesSomos/";
import { PrimeraVisitaView, QuiropracticaView } from "../views/Quiropractica";

const AppRouter = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<HomeView />} />
        <Route exact path="/quiropractica" element={<QuiropracticaView />} />
        <Route exact path="/primera-visita" element={<PrimeraVisitaView />} />
        <Route
          exact
          path="/salud-y-bienestar"
          element={<SaludBienestarView />}
        />
        <Route exact path="/bebes-y-ninos" element={<BebesNinosView />} />
        <Route exact path="/durante-el-embarazo" element={<EmbarazoView />} />
        <Route exact path="/tercera-edad" element={<TerceraEdadView />} />
        <Route exact path="/deporte" element={<DeporteView />} />
        <Route exact path="/dolor-espalda" element={<DoloresView />} />
        <Route exact path="/equipo" element={<QuienesSomosView />} />
        <Route exact path="/contacto" element={<ContactoView />} />
        <Route exact path="/oferta" element={<OfertaView />} />
        <Route path="*" element={<ErrorView />} />
      </Routes>
      <Footer />
      <BtnWhatsApp />
    </Router>
  );
};

export default AppRouter;
