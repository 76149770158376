import { Container } from "@mui/system";
import React from "react";
import { Grid, ListItemIcon, MenuItem } from "@mui/material";
import { Check } from "@mui/icons-material";

const SectionList = ({
  title,
  description_left,
  description_right,
  background = "",
}) => {
  return (
    <div className={"section " + background}>
      <Container>
        <Grid container spacing={2}>
          <>
            <Grid className="section__container__text" item xs={12}>
              <h4>{title}</h4>
            </Grid>
            <Grid
              className="section__container__text start"
              item
              xs={12}
              md={6}
            >
              {description_left.map((item, index) => (
                <p className="list__item" key={index}>
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                  {item}
                </p>
              ))}
            </Grid>
            <Grid
              className="section__container__text start right"
              item
              xs={12}
              md={6}
            >
              {description_right.map((item, index) => (
                <p className="list__item" key={index}>
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                  {item}
                </p>
              ))}
            </Grid>
          </>
        </Grid>
      </Container>
    </div>
  );
};

export default SectionList;
