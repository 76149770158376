const title = "Quiropráctica Más";
const title_bajada = "Allá De Los 60";
const description = "El Comienzo De Una Nueva Vida.";
const url_img = `banner_home.jpg`;

const title_section_1 = `El Comienzo De Una Nueva Vida`;
const description_section_1 = `La asistencia quiropráctica mejora notablemente la calidad de vida en adultos mayores. Por sobre todas las cosas, les brinda una sensación de bienestar y autosuficiencia que no requiere de medicamentos.`;
const url_img_section_1 = `tercera_edad.png`;


const title_section_2 = `Entre los resultados esperados se incluyen:`;
const description_left_section_2 = [
  "Más vitalidad y energía.",
  "Aumento del rango de movimiento en la columna vertebral y extremidades.",
  "Aumento del equilibrio y coordinación.",
  "Disminución de la degeneración articular.",
  "Motivación para estar más activo.",
];
const description_right_section_2 = [
  "Mejoría de la función del sistema inmunológico.",
  "Mejoría de la circulación sanguínea.",
  "Disminución del dolor.",
  "Mayor claridad.",
  "Mayor relajación.",
];

const description_section_3 = 'Un estudio reciente publicado en Temas de Quiropráctica Clínica concluyó que era “menos probable que los pacientes de la tercera edad que recibían cuidado quiropráctico hubieran sido hospitalizados y hubieran asistido a una residencia de ancianos; Además, era más probable que gozaran de buena salud, que hicieran ejercicio vigoroso y que gozaran de movilidad dentro de la comunidad al encontrarse recibiendo cuidado quiropráctico habitual”.';

export {
    title,
    title_bajada,
    description,
    url_img,
    title_section_1,
    description_section_1,
    url_img_section_1,
    title_section_2,
    description_left_section_2,
    description_right_section_2,
    description_section_3,
};
