import React, { useContext, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination } from "swiper";
import { SectionImg, SectionVideo, Banner, BtnOferta } from "../../components/shared";

import {
  images,
  title,
  title_bajada,
  description,
  url_img,
  title_bienvenido,
  title_section_1,
  description_bienvenido,
  url_bienvenido,
  description_section_1,
  title_que_es_1,
  description_que_es_1,
  url_que_es,
  title_section_2,
  url_img_section_1,
  description_section_2,
  title_video_section_2,
  url_video_section_2,
  title_adecuada,
  description_adecuada,
  url_adecuada,
} from "./const";
import goToTop from "../../hooks/useTop";
import { UserContext } from "../../context/UserContext";

const HomeView = () => {
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);
  useEffect(() => {
    setMenuActive(1);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Banner
        title={title}
        title_bajada={title_bajada}
        description={description}
        url_img={url_img}
      />

      <SectionImg
        title={title_bienvenido}
        description={description_bienvenido}
        left={false}
        url_img={url_bienvenido}
      />

      <SectionImg
        title={title_section_1}
        description={description_section_1}
        left={true}
        url_img={url_img_section_1}
        background={"grey-color"}
      />

      <SectionImg
        title={title_que_es_1}
        description={description_que_es_1}
        left={false}
        url_img={url_que_es}
      />

      <SectionVideo
        title={title_section_2}
        description={description_section_2}
        left={true}
        url_video={url_video_section_2}
        title_video={title_video_section_2}
        background={"grey-color"}
      />

      <SectionImg
        title={title_adecuada}
        description={description_adecuada}
        left={false}
        url_img={url_adecuada}
      />

      <div className="section brown-color">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          breakpoints={{
            600: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            920: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1536: {
              slidesPerView: 3,
              spaceBetween: 100,
            },
          }}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {images.map((image) => (
            <SwiperSlide key={image}>
              <div className="swiper-zoom-container">
                <img
                  src={process.env.REACT_APP_FRONTEND + "assets/image/" + image}
                  alt={"description of image" + image}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <BtnOferta />
    </>
  );
};

export default HomeView;
