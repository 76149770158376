import { Container } from "@mui/system";
import React from "react";
import { Grid } from "@mui/material";

const SectionVideo = ({
  title,
  description,
  left,
  url_video,
  title_video,
  background = "",
}) => {
  return (
    <div className={"section " + background}>
      <Container>
        <Grid container spacing={2}>
          {left && (
            <>
              {" "}
              <Grid className="section__container__video" item xs={12} md={6}>
                <iframe
                  title={title_video}
                  data-image-dimensions="1280x720"
                  mozallowfullscreen=""
                  allowfullscreen="true"
                  src={url_video}
                  data-embed="true"
                  webkitallowfullscreen=""
                  frameborder="0"
                ></iframe>
              </Grid>
              <Grid
                className="section__container__text right"
                item
                xs={12}
                md={6}
              >
                <h4>{title}</h4>
                <p>{description}</p>
              </Grid>
            </>
          )}
          {!left && (
            <>
              <Grid
                className="section__container__text"
                item
                xs={12}
                md={6}
              >
                <h4>{title}</h4>
                <p>{description}</p>
              </Grid>
              <Grid
                className="section__container__video right"
                item
                xs={12}
                md={6}
              >
                <iframe
                  title={title_video}
                  data-image-dimensions="1280x720"
                  mozallowfullscreen=""
                  allowfullscreen="true"
                  src={url_video}
                  data-embed="true"
                  webkitallowfullscreen=""
                  frameborder="0"
                  autoplay="true"
                ></iframe>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default SectionVideo;
