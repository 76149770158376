import { React, useContext, useEffect } from "react";
import { Banner } from "../../components/shared";
import { UserContext } from "../../context/UserContext";
import goToTop from "../../hooks/useTop";
import FormContact from "../Contacto/FormContact";
import "./styles.scss";
import { title, title_bajada, description, url_img, resenas } from "./const";
import { Container, Grid } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";

const OfertaView = () => {
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(0);
    setSubmenuActive(0);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Banner
        title={title}
        title_bajada={title_bajada}
        description={description}
        url_img={url_img}
        positionBottom={true}
        btn_form={true}
      />

      <div className="section__oferta">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <div className="items__oferta">
              <h4>1 ESTUDIO</h4>
            </div>
            <div className="description__items">
              <p>Para poder evaluar tu caso y darte el mejor cuidado.</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <div className="items__oferta">
              <h4>1 CHEQUEO POSTURAL</h4>
            </div>
            <div className="description__items">
              <p>Para analizar cómo está tu postura.</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <div className="items__oferta">
              <h4>1 AJUSTE QUIROPRÁCTICO</h4>
            </div>
            <div className="description__items">
              <p>Para aliviar tu malestar físico.</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <div className="items__oferta">
              <h4>INFORME CON RESULTADOS</h4>
            </div>
            <div className="description__items">
              <p>
                Para saber exactamente dónde está el problema y cómo podemos
                ayudarte.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="section__oferta color__price">
        <Container className="content__price">
          <h4>
            TODO ESTO POR SOLO € 30{" "}
            <span className="text__through">(€ 60)</span>
          </h4>
          <a className="content__btn__reserva" href="#section__form">
            <p>RESERVAR</p>
          </a>
        </Container>
      </div>

      <div className="section__oferta color__grey">
        <Container>
          <h4>Qué Dicen Nuestros Pacientes de Nosotros</h4>
          <Swiper
            autoHeight={true}
            navigation={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation]}
            style={{ background: "transparent" }}
          >
            {resenas.map((resena) => (
              <SwiperSlide key={resena.id}>
                <div className="swiper-slide" style={{ width: "100%" }}>
                  <div className="content-review">
                    <div className="img-review">
                      <img
                        src={resena.reviewer_picture_url}
                        alt={resena.reviewer_name}
                        width="44"
                        height="44"
                      />
                    </div>
                    <div className="title-name-review">
                      <span>{resena.reviewer_name}</span>
                    </div>
                    <div>
                      {resena.rating === 0 ? (
                        <>
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/no_estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/no_estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/no_estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/no_estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/no_estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                        </>
                      ) : resena.rating > 0 && resena.rating <= 1 ? (
                        <>
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/no_estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/no_estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/no_estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/no_estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                        </>
                      ) : resena.rating > 1 && resena.rating <= 2 ? (
                        <>
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/no_estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/no_estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/no_estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                        </>
                      ) : resena.rating > 2 && resena.rating <= 3 ? (
                        <>
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/no_estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/no_estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                        </>
                      ) : resena.rating > 3 && resena.rating <= 4 ? (
                        <>
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/no_estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                        </>
                      ) : resena.rating > 4 && resena.rating <= 5 ? (
                        <>
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                          <img
                            src={
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/estrella.png"
                            }
                            alt="Estrella"
                            className="estrella"
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="SimpleShortener__Inner-sc-19xjxqz-1 elFFwM content-review">
                      {resena.text}
                    </div>
                    <div className="ReviewPostedOn__LogoStyle-sc-1s508wm-2 bikRDn">
                      <div className="ReviewPostedOn__Label-sc-1s508wm-5 brQPpJ">
                        Posted on
                      </div>
                      <div
                        title="Google"
                        className="Icon__IconContainer-sc-4jqwpl-0 bqPybg"
                      >
                        <div>
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 85 36"
                              className="injected-svg"
                              data-src="https://static.elfsight.com/icons/google-logo-multicolor.svg"
                            >
                              <g fill="none">
                                <path
                                  fill="#4285F4"
                                  d="M20.82 13.829h-9.86v2.926h6.994c-.346 4.104-3.76 5.854-6.983 5.854-4.123 0-7.72-3.244-7.72-7.791 0-4.43 3.43-7.841 7.73-7.841 3.317 0 5.272 2.115 5.272 2.115l2.05-2.122s-2.63-2.928-7.427-2.928C4.767 4.042.042 9.197.042 14.765c0 5.457 4.445 10.777 10.989 10.777 5.755 0 9.968-3.943 9.968-9.773 0-1.23-.178-1.94-.178-1.94Z"
                                ></path>
                                <path
                                  fill="#EA4335"
                                  d="M28.9 11.71c-4.047 0-6.947 3.163-6.947 6.853 0 3.744 2.812 6.966 6.994 6.966 3.785 0 6.886-2.893 6.886-6.886 0-4.576-3.607-6.934-6.934-6.934Zm.04 2.714c1.99 0 3.875 1.609 3.875 4.2 0 2.538-1.878 4.193-3.885 4.193-2.205 0-3.945-1.766-3.945-4.212 0-2.394 1.718-4.181 3.954-4.181Z"
                                ></path>
                                <path
                                  fill="#FBBC05"
                                  d="M44.008 11.71c-4.047 0-6.947 3.163-6.947 6.853 0 3.744 2.813 6.966 6.994 6.966 3.785 0 6.886-2.893 6.886-6.886 0-4.576-3.607-6.934-6.933-6.934Zm.04 2.714c1.99 0 3.876 1.609 3.876 4.2 0 2.538-1.878 4.193-3.885 4.193-2.206 0-3.945-1.766-3.945-4.212 0-2.394 1.718-4.181 3.954-4.181Z"
                                ></path>
                                <path
                                  fill="#4285F4"
                                  d="M58.825 11.717c-3.714 0-6.633 3.253-6.633 6.904 0 4.16 3.384 6.918 6.57 6.918 1.969 0 3.016-.782 3.79-1.68v1.363c0 2.384-1.448 3.812-3.633 3.812-2.111 0-3.17-1.57-3.538-2.46l-2.655 1.11c.942 1.992 2.838 4.07 6.215 4.07 3.693 0 6.507-2.327 6.507-7.205V12.132h-2.897v1.17c-.89-.96-2.108-1.585-3.726-1.585Zm.27 2.709c1.82 0 3.69 1.554 3.69 4.21 0 2.699-1.866 4.187-3.731 4.187-1.98 0-3.823-1.608-3.823-4.161 0-2.653 1.914-4.236 3.863-4.236Z"
                                ></path>
                                <path
                                  fill="#EA4335"
                                  d="M78.33 11.7c-3.504 0-6.445 2.788-6.445 6.901 0 4.353 3.279 6.934 6.781 6.934 2.924 0 4.718-1.6 5.79-3.033l-2.39-1.589c-.62.962-1.656 1.902-3.385 1.902-1.942 0-2.836-1.064-3.389-2.094l9.266-3.845-.481-1.126c-.896-2.207-2.984-4.05-5.747-4.05Zm.12 2.658c1.263 0 2.172.671 2.558 1.476L74.82 18.42c-.267-2.002 1.63-4.062 3.63-4.062Z"
                                ></path>
                                <path
                                  fill="#34A853"
                                  d="M67.467 25.124h3.044V4.757h-3.044z"
                                ></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </div>

      <FormContact />
    </>
  );
};

export default OfertaView;
