import { React, useContext, useEffect } from "react";
import { Banner, BtnOferta, SectionList, SectionText } from "../../components/shared";
import {
  title,
  title_bajada,
  description,
  url_img,
  title_section_1,
  description_left_section_1,
  description_right_section_1,
  title_section_2,
  description_left_section_2,
  description_right_section_2,
  dataComment,
} from "./constDeporte";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
// import required modules
import { EffectCards } from "swiper";
import { Container } from "@mui/system";
import { Grid } from "@mui/material";
import goToTop from "../../hooks/useTop";
import { UserContext } from "../../context/UserContext";

const DeporteView = () => {
  const { setMenuActive, setSubmenuActive } = useContext(UserContext);

  useEffect(() => {
    setMenuActive(3);
    setSubmenuActive(7);
    goToTop();
  }, [setMenuActive, setSubmenuActive]);
  return (
    <>
      <Banner
        title={title}
        title_bajada={title_bajada}
        description={description}
        url_img={url_img}
      />

      <SectionText
        title={title_section_1}
        description_left={description_left_section_1}
        description_right={description_right_section_1}
      />

      <SectionList
        title={title_section_2}
        background={"grey-color"}
        description_left={description_left_section_2}
        description_right={description_right_section_2}
      />
      <div className={"section"}>
        <Container>
          <Grid className="section__container__text">
            <h4>
              A Continuación Te Contamos Lo Que Algunos Atletas De Élite Dicen
              Acerca Del Cuidado Quiropráctico:
            </h4>
          </Grid>
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            className="comment__swiper"
          >
            {dataComment.map((item) => (
              <SwiperSlide key={item.name}>
                <h4>{item.name}</h4>
                <p>{item.comment}</p>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </div>
      <BtnOferta />
    </>
  );
};

export default DeporteView;
