import { Grid, IconButton } from "@mui/material";
import { Container } from "@mui/system";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <footer>
        <div className="footer__content">
          <Container fixed>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <h4>Maresme Quiropráctic</h4>
                <p>Centro Quiropráctico</p>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <h4>Estamos en</h4>
                <p>
                  Plaza Àngel Guimerà, 4, 08340
                  <br />
                  Vilassar de Mar, Barcelona
                </p>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <h4>Contactanos</h4>
                <p>
                  hola@maresmequiropractic.com
                  <br />
                  937 525 355
                </p>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <h4>Links</h4>
                <p>
                  <Link to="/">Aviso Legal, Privacidad y Cookies</Link>
                </p>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Container fixed>
          <div className="footer__redes_sociales">
            <div>
              <p>© {year} Maresme Quiropráctic</p>
            </div>
            <div>
              {" "}
              <IconButton href="https://www.facebook.com/maresmequiropractic/">
                <FacebookIcon />
              </IconButton>
              <IconButton href="https://www.instagram.com/maresmequiropractic/">
                <InstagramIcon />
              </IconButton>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
