const title = "Quiropráctica Para La Salud";
const title_bajada = "Y Bienestar de tu Familia";
const description =
  "La quiropráctica es utilizada por millones de familias que han decidido cuidar de su salud.";
const url_img = `banner_edad.jpg`;

const description_section_1 = ['La quiropráctica es utilizada por millones de familias que han decidido cuidar de su salud.', 'La mayoría de nuestros pacientes se benefician del cuidado quiropráctico no solo para el alivio del dolor, si no también para prevenir problemas y mantenerse saludables.', 'Desde el nacimiento, estamos constantemente sometidos a diversas formas de estrés, ya sea emocional, químico o físico.', 'Cuando el sistema nervioso no es capaz de asimilar el estrés del entorno, lo compensa creando desalineaciones de la columna vertebral (subluxaciones vertebrales) que pueden provocar interferencias en el funcionamiento del sistema nervioso y, por tanto, afectando al resto de nuestro cuerpo.', 'Los ajustes quiroprácticos permiten corregir las subluxaciones, equilibrando la columna vertebral y liberando al sistema nervioso. Cuando el sistema nervioso está libre de interferencias y la comunicación entre el cuerpo y el cerebro es óptima, el cuerpo puede curarse, adaptarse a las distintas formas del estrés y maximizar el potencial humano a todos los niveles.'];

export {
  title,
  title_bajada,
  description,
  url_img,
  description_section_1,
};
