const title = "404";
const title_bajada = "Page Not Found";
const description =
  "The resource requested could not be found on this server.";
const url_img = `banner_quiropractica.jpg`;

export {
  title,
  title_bajada,
  description,
  url_img,
};
