import React from "react";
import { Link } from "react-router-dom";

const BtnOferta = () => {
  return (
    <Link className="container__btn__oferta" to="/oferta">
      Oferta Especial
    </Link>
  );
};

export default BtnOferta;
